import axios from 'axios';
import Qs from 'qs';
import apiAll from './api';

// let base = 'https://gpt-521.com/mt/s/';
let base = '';

let config = {
    transformRequest: [function (data) {
        return Qs.stringify(data)
    }],
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}
export const getUserInfoApi = params => { return axios.post(`${base}/mt/s/WzUserModel/getUserInfo`, params,config).then(res => res.data); };//用户详情
export const getAllAdTaskApi = params => { return axios.post(`${base}/mt/s/WzAdDefineModel/getAllAdTask`, params,config).then(res => res.data); };//广告任务
export const getShowOrderApi = params => { return axios.post(`${base}/mt/s/WzUserOrderModel/getShowOrder`, params,config).then(res => res.data); };//排行榜
export const getHistoryApi = params => { return axios.post(`${base}/mt/s/WzWatchHistoryModel/getHistory`, params,config).then(res => res.data); };//任务历史
export const beginWatchAdApi = params => { return axios.post(`${base}/mt/s/WzWatchHistoryModel/beginWatchAd`, params,config).then(res => res.data); };//开始任务
export const getTaskLinkApi = params => { return axios.post(`${base}/mt/s/WzAdDefineModel/getTaskLink`, params,config).then(res => res.data); };//获取跳转链接